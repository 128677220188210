import Vue from 'vue'
import App from './App.vue'
import createRouter from './router'
import createStore from './store'
const placeholderimage = require('@/assets/placeholder.png')
import VueLazyload from 'vue-lazyload'
import global from '@/utils/global'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics'
import VueMeta from 'vue-meta'

Vue.prototype.$global = global

Vue.use(VueMeta)

Vue.use(VueLazyload, {
	preLoad: 1.3,
	error: placeholderimage,
	loading: placeholderimage,
	attempt: 1,
})
const defaultFireBase = {
	apiKey: 'AIzaSyBcS3cwlUXpK99s0FiNLcdhiTqTbqa8pRo',
	authDomain: 'webs-58a8d.firebaseapp.com',
	projectId: 'webs-58a8d',
	storageBucket: 'webs-58a8d.appspot.com',
	messagingSenderId: '730684174767',
	appId: '1:730684174767:web:903bde34269f9e520c3f5a',
	measurementId: 'G-8YBZ1WWK4W',
}

function initializeFirebase() {
	const firebaseConfig = window.__INITIAL_STATE__ ? window.__INITIAL_STATE__.firebaseConfig : defaultFireBase
	let app = initializeApp(firebaseConfig)
	let analytics = getAnalytics(app)
	return analytics
}

async function setupAnalytics() {
	// 判断 Firebase Analytics 是否支持
	const isClientSide = typeof window !== 'undefined'
	const isSupportedResult = isClientSide ? await isSupported() : false

	// 定义 $eventrack 函数
	Vue.prototype.$eventrack = isSupportedResult
		? (msg, method, map = {}) => {
				const analytics = initializeFirebase()
				const params = {
					time: new Date(),
					message: msg,
					method: method,
					...map,
				}
				logEvent(analytics, msg, params)
				console.log('Analytics event tracked:', msg, params)
			}
		: (event, params = {}) => {
				const logMessage = isClientSide ? `Analytics not supported: ${event}` : `Server Log: ${event}`
				console.log(logMessage, params)
			}

	// 如果是客户端且支持 Firebase Analytics，初始化并记录初始事件
	if (isClientSide && isSupportedResult) {
		const analytics = initializeFirebase()
		logEvent(analytics, 'in_page')
		console.log('in_page')
	}
}

// 在客户端调用 setupAnalytics
setupAnalytics()

export default function createApp() {
	const router = createRouter()
	const store = createStore()
	const app = new Vue({
		router,
		store,
		render: (h) => h(App),
	})
	return {
		app,
		router,
		store,
	}
}
