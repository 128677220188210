import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			deviceType: 'pc',
			// deviceType: 'mobile',
			showDebug: false,
			adsensConfig: {
				scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3256399379577513',
				// ads: 'google.com, pub-3256399379577513, DIRECT, f08c47fec0942fa0',
			},
			firebaseConfig: {
				apiKey: 'AIzaSyBcS3cwlUXpK99s0FiNLcdhiTqTbqa8pRo',
				authDomain: 'webs-58a8d.firebaseapp.com',
				projectId: 'webs-58a8d',
				storageBucket: 'webs-58a8d.appspot.com',
				messagingSenderId: '730684174767',
				appId: '1:730684174767:web:903bde34269f9e520c3f5a',
				measurementId: 'G-8YBZ1WWK4W',
			},
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsensConfig: (state) => state.adsensConfig,
		},
	})
}
